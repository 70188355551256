.jumbotron {
  background-image: url("../img/jumbotron.jpg");
  background-size: cover;
  background-position: center;
}
.half {
  background-image: url("../img/half.jpg");
  background-size: cover;
  background-position: center;
}
.bg-gray {
  background-color: #f5f5f5;
}
.octagon {
  bottom: 0px;
  clear: both;
  color: rgb(119, 119, 119);
  float: left;
  height: 100px;
  left: 0px;
  position: relative;
  right: 0px;
  text-decoration: none solid rgb(119, 119, 119);
  top: 0px;
  width: 100px;
  column-rule-color: rgb(119, 119, 119);
  perspective-origin: 50px 50px;
  transform-origin: 50px 50px;
  caret-color: rgb(119, 119, 119);
  border: 0px none rgb(119, 119, 119);
  margin: 10px 0px 0px;
  outline: rgb(119, 119, 119) none 0px;
} /*#DIV_1*/

.octagon:after {
  bottom: 0px;
  color: rgb(119, 119, 119);
  content: "";
  display: block;
  height: 0px;
  left: 0px;
  position: absolute;
  right: 0px;
  text-decoration: none solid rgb(119, 119, 119);
  top: 71px;
  width: 42px;
  column-rule-color: rgb(119, 119, 119);
  perspective-origin: 50px 14.5px;
  transform-origin: 50px 14.5px;
  caret-color: rgb(119, 119, 119);
  border-top: 29px solid rgb(30, 105, 184);
  border-right: 29px solid rgba(0, 0, 0, 0);
  border-bottom: 0px none rgb(119, 119, 119);
  border-left: 29px solid rgba(0, 0, 0, 0);
  outline: rgb(119, 119, 119) none 0px;
  box-sizing: content-box;
} /*#DIV_1:after*/

.octagon:before {
  bottom: 71px;
  color: rgb(119, 119, 119);
  content: "";
  display: block;
  height: 0px;
  left: 0px;
  position: absolute;
  right: 0px;
  text-decoration: none solid rgb(119, 119, 119);
  top: 0px;
  width: 42px;
  column-rule-color: rgb(119, 119, 119);
  perspective-origin: 50px 14.5px;
  transform-origin: 50px 14.5px;
  caret-color: rgb(119, 119, 119);
  border-top: 0px none rgb(119, 119, 119);
  border-right: 29px solid rgba(0, 0, 0, 0);
  border-bottom: 29px solid rgb(30, 105, 184);
  border-left: 29px solid rgba(0, 0, 0, 0);
  outline: rgb(119, 119, 119) none 0px;
  box-sizing: content-box;
} /*#DIV_1:before*/

.octagon-inner {
  color: rgb(119, 119, 119);
  text-decoration: none solid rgb(119, 119, 119);
  width: 100px;
  column-rule-color: rgb(119, 119, 119);
  perspective-origin: 50px 0px;
  transform-origin: 50px 0px;
  caret-color: rgb(119, 119, 119);
  border: 0px none rgb(119, 119, 119);
  outline: rgb(119, 119, 119) none 0px;
} /*#DIV_2*/

.octagon-inner:before {
  bottom: 29px;
  clear: both;
  color: rgb(255, 255, 255);
  display: block;
  height: 42px;
  font-size: 42px;
  left: 0px;
  position: absolute;
  right: 0px;
  speak: none;
  text-align: center;
  text-decoration: none solid rgb(255, 255, 255);
  top: -19px;
  width: 100px;
  z-index: 2;
  column-rule-color: rgb(255, 255, 255);
  perspective-origin: 50px 21px;
  transform-origin: 50px 21px;
  caret-color: rgb(255, 255, 255);
  background: rgb(30, 105, 184) none repeat scroll 0% 0% / auto padding-box
    border-box;
  border: 0px none rgb(255, 255, 255);
  margin: 29px 0px 0px;
  outline: rgb(255, 255, 255) none 0px;
  box-sizing: content-box;
}
.m-150 {
  margin-left: 150px;
}
.info-box {
  display: flex;
  align-items: center;
}
.info-box .oi {
  padding: 0.6em;
  font-size: 24px;
  border-radius: 100%;
  border: 2px solid;
  width: 57px;
  height: 57px;
}
.info-box p {
  font-size: 21px;
  margin-left: 25px;
  margin-bottom: 0;
}
.navbar {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 999;
}
.navbar-brand {
  width: 40%;
}
